import DatePicker from "vue2-datepicker";
import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from 'moment';
import Loading from '@/components/widgets/Loading.vue'

export default {
  props: {
    opened: {
        type: Boolean,
        default: false
    },
    mode: {
        type: String
    },
    id: {
        type: String
    }
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.drawerOpened = n
        if (this.mode === 'update') {
          this.onInitData()
        }
      }
    }
  },
  components: {
    DatePicker,
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      drawerOpened: false,
      form: {
        lot: null,
        brand: null,
        invoice_no: null,
        contact_no: null,
        date: new Date(),
        coil_no: null,
        size: null,
        color: null,
        net_weight: null,
        net_gross: null,
        length: null,
        if_other_brand: null,
        can_pick: true,
        note: null,
        price: null,
      },
    }
  },
  validations: {
    form: {
      lot: { required },
      invoice_no: { required },
      date: { required },
      brand: { required },
      coil_no: { required },
      size: { required },
      color: { required },
      net_weight: { required },
      net_gross: { required },
      length: { required },
      price: {},
      can_pick: {},
      note: {},
      if_other_brand: { required: requiredIf(function() {
        return this.form.brand === 'OT';
      }) },
    },
  },
  methods: {
    async onSubmitForm() {
      this.loading = true
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const formData = new FormData()
      formData.append('lot_no', this.form.lot)
      formData.append('invoice_no', this.form.invoice_no)
      formData.append('invoice_date', moment(this.form.date).format('YYYY-MM-DD'))
      formData.append('brand', this.form.brand)
      formData.append('coil', this.form.coil_no)
      formData.append('size', this.form.size)
      formData.append('color', this.form.color)
      formData.append('net_weight', this.form.net_weight)
      formData.append('gross_weight', this.form.net_gross)
      formData.append('length', this.form.length)
      formData.append('if_other_brand', this.form.if_other_brand)
      formData.append('price', this.form.price)
      formData.append('can_pick', this.form.can_pick)
      formData.append('note', this.form.note)

      try {
        await this.$axios.post(this.$host + "/api/v1/china-order" + (this.mode === 'update' ? '/' + this.id  : ''), formData,{
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
  
        this.loading = false
        this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
        }).then(result => {
          this.onCloseDrawer()
        })
      } catch (error) {
        this.onExceptionHandler(error.response.data.message)
      }
    },
    async onInitData() {
      try {
        this.loading = true
        const res = await this.$axios.get(this.$host + "/api/v1/china-order/" + this.id, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        this.form.lot = res.data.lot_no
        this.form.invoice_no = res.data.invoice_no
        this.form.date = new Date(res.data.invoice_date)
        this.form.coil_no = res.data.coil
        this.form.size = res.data.size
        this.form.color = res.data.color
        this.form.net_weight = res.data.net_weight
        this.form.net_gross = res.data.gross_weight
        this.form.length = res.data.length
        this.form.brand = res.data.brand
        this.form.if_other_brand = res.data.if_other_brand
        this.form.price = res.data.price
        this.form.can_pick = res.data.can_pick === 1 ? true : false
        this.form.note = res.data.note

        this.loading = false
        this.$v.form.$touch()
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message)
      }
    },
    resetForm() {
      this.form.lot = null
      this.form.brand = null
      this.form.invoice_no = null
      this.form.contact_no = null
      this.form.coil_no = null
      this.form.size = null
      this.form.color = null
      this.form.net_weight = null
      this.form.net_gross = null
      this.form.length = null
      this.form.if_other_brand = null
      this.form.price = null
      this.form.can_pick = null
      this.note = null

      this.$nextTick(() => {
        this.$v.$reset();
      })
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    }
  }
}